<template>
    <el-dialog class="withDepostDialog" :visible="withdrawalShow" @close="closeDialog('all')" :show-close="false">
        <div class="diaTitle">
            <div class="diaTit">取款</div>
            <img src="@/assets/postWith/titleColse.svg" @click="closeDialog('all')" />
        </div>
        <div class="contents">
            <div class="withTop">
                <div class="blueTop">
                    <div class="blueLeft">
                        <div class="leftTop">
                            <div class="blueTitle">
                                中心钱包
                                <img class="reload" @click="init()"
                                    :class="{ 'rotate': getWalletLoading ||
                    recoveryLoading ||
                    walletLoading ||
                    getchannelLoading ||
                    getUserLoading, }"
                                    src="@/assets/postWith/sgya.svg" />
                            </div>
                            <div class="blueRight"  @click="recovery">
                                <img class="reload" src="@/assets/postWith/blueSx.svg" :class="{
                rotate:
                  getWalletLoading ||
                  recoveryLoading ||
                  walletLoading ||
                  getchannelLoading ||
                  getUserLoading,
              }"/>
                                一键回收
                            </div>
                        </div>
                        <div class="numbers">
                            <img src="@/assets/postWith/money.svg" />
                            {{ Math.trunc(totalAmount).toFixed(2) | formatNumber }}
                        </div>
                    </div>
                    <div class="shu"></div>
                    <div class="blue-new-right">
                        <div class="blueTitle ">
                            锁定金额
                        </div>
                        <div class="subNumbers">
                            <img src="@/assets/postWith/money.svg" />
                            {{ Math.trunc(freeze) | formatNumber }}
                        </div>
                    </div>
                </div>
                <!-- <div class="openCo">
                    <van-switch size="16px" class="open" inactive-color="rgba(255, 255, 255, 0.09)"
                        v-model="checked" />隐藏无余额场馆
                </div>
                <div class="blueBom blueOne" v-show="!checked">
                    <div class="withTit">
                        <div class="withTitle">体育</div>
                        <div class="cont">0.00</div>
                    </div>
                    <div class="bomShu"></div>
                    <div class="withTit">
                        <div class="withTitle">真人</div>
                        <div class="cont">0.00</div>
                    </div>
                    <div class="bomShu"></div>
                    <div class="withTit">
                        <div class="withTitle">电子</div>
                        <div class="cont">0.00</div>
                    </div>
                    <div class="bomShu"></div>
                    <div class="withTit">
                        <div class="withTitle">捕鱼</div>
                        <div class="cont">0.00</div>
                    </div>
                    <div class="bomShu"></div>
                    <div class="withTit">
                        <div class="withTitle">区块链</div>
                        <div class="cont">0.00</div>
                    </div>
                </div> -->

            </div>

            <div class="wbxqblm" >
                <div class="title">
                    <div class="blueVertical"></div>
                    <div>取款方式</div>
                </div>
                <div class="seleDemo">
                <div class="seleContent">
                    <div class="seleList" v-for="(item, index) in list" :key="index"
                        :class="{ 'active': actSeleWith == index }" @click="selectAct(item, index)">
                        {{ item.name }}
                        <div class="customBom"></div>
                    </div>
                </div>
            </div>

            </div>
            

            <div class="wbxqblm" v-if="actSeleWith > -1">
                <div class="title">
                    <div class="blueVertical"></div>
                    <div> {{ actWith == "银行卡" ? "银行卡" : "收款地址" }} ({{
          actWith == "银行卡" ? total : totalUsdt
        }}/5)</div>
        <!--$router.push('/account/index') addVirtual -->
                    <div class="titBtn" @click="actWith == '银行卡' ? goAdd('addLink') : goAdd('addVirtual') ">
                        <img src="@/assets/postWith/add.svg" />
                        添加
                    </div>
                </div>
                <div class="tis" v-if="actSeleWith > -1"
                    v-show="actWith == '银行卡' ? total <= 0 : totalUsdt <= 0">请先绑定一张{{ actWith == "银行卡" ? "银行卡" : "虚拟币" }},用于收款
                </div>

                <div class="linkDemo"  v-show="actWith == '银行卡'">
                    <div class="linkContent" v-for="(item, index) in linkList" :key="index + item"
                        @click="activeLink = index">
                        <div class="linkLeft">
                            <div class="btTit">{{ item.accountHolder }}</div>
                            <div class="linkShu"></div>
                            <div v-html="hideMiddle(item.bankCardNumber)"></div>
                        </div>
                        <div class="dhRight"><img v-show="activeLink == index" src="@/assets/postWith/selectDu.svg" />
                        </div>
                    </div>
                </div>
                <div class="linkDemo" v-show="actWith == '虚拟币'">
                    <div class="linkContent" v-for="(item, index) in usdList" :key="index" @click="activeLink = index">
                        <div class="linkLeft">
                            <div class="btTit">{{ item.cryptocurrencyName }}</div>
                            <div class="linkShu"></div>
                            <div v-html="hideMiddle(item.cryptocurrencyAddress)"></div>
                        </div>
                        <div class="dhRight"><img v-show="activeLink == index" src="@/assets/postWith/selectDu.svg" />
                        </div>
                    </div>
                </div>

            </div>

            <div class="wbxqblm"  v-if="actSeleWith > -1">

                <div class="title">
                    <div class="blueVertical"></div>
                    <div>取款金额</div>

                    <div class="jrTs" v-if="
        actSeleWith > -1 && (actWith == '银行卡' ? total > 0 : totalUsdt > 0)
      ">
                        <img src="@/assets/postWith/greenLamp.svg" />
                        今日可用取款次数：{{ withdrawCount }} 次，今日可用取款额度：{{ configMaxAmount }}元，单次最高取款额度：{{
        accumulateWithdrawAmount }}元
                    </div>
                </div>

                <div class="intPost">
                    <div class="intLeft">
                        <img class="dollarImg" src="@/assets/postWith/dollar.svg" />
                        <el-input class="amoutInt" v-model="aount" type="number"
                            :placeholder="`限额${minAmount} ~ ${maxAmount}元`"></el-input>
                        <div class="intRight">
                            <div class="rmbShu"></div>
                            <div class="maxBtn" @click="maxMoney">最大</div>
                        </div>
                        <!-- <div class="colse" @click="colsePost"><img src="@/assets/postWith/colse.svg" /></div> -->
                    </div>

                </div>

                <!-- <div class="withInt">
                    <van-field class="ints" type="number" :border="false" v-model="aount"
                        :placeholder="`请输入${minAmount}-${maxAmount}元`" />
                    <div class="intRight">
                        <div class="rmbShu"></div>
                        <div class="maxBtn" @click="maxMoney">最大</div>
                    </div>
                </div> -->
                <div class="yuji" v-if="
        list.length > 0 &&
        actSeleWith > -1 &&
        (actWith == '银行卡' ? total > 0 : totalUsdt > 0)
      ">
                    <!-- <div>预计到账 <span>{{ aount * (1 - list[actWith].rate) }}</span>元</div>
                    <div>预计扣除手续费 <span>{{ aount * list[actWith].rate }}</span>元</div> -->
                    <div>
                        预计到账 
                        <span>{{
                        actWith == "银行卡"
                            ? (aount * (1 - list[actSeleWith].rate)).toFixed(3)
                            : (aount / (list[actSeleWith].rate)).toFixed(3)
                        }}</span
                        >{{ actWith == "银行卡" ? "元" : "USDT" }}
                    </div>
                    <div v-show="actWith == '银行卡'">
                        预计扣除手续费
                        <span>{{ (aount * list[actSeleWith].rate).toFixed(3) }}</span
                        >{{ actWith == "银行卡" ? "元" : "USDT" }}
                    </div>
                    <div v-show="actWith == '虚拟币'">
                        参考汇率 <span>{{ list[actSeleWith].rate }}</span>
                    </div>
                </div>
                <!-- <div class="wlfb">为了方便您快速取款，请先将所有场馆钱包余额回收至中心钱包</div> -->
            </div>

            <div class="ckydwt">
                <div class="encounter">
                    存款遇到问题？联系<img src="@/assets/login/service.svg"  @click="getService()" /><span @click="getService()">在线客服</span>
                </div>
                <van-button
                :disabled="aount < minAmount || aount > maxAmount || subLoading || (actWith == '银行卡' ? total <= 0 : totalUsdt <= 0)"
                class="btn" :loading="subLoading" @click="submit">立即取款</van-button>
            </div>

           

            <!-- <div class="ckydwt">取款遇到问题？联系<span>人工客服</span>解决</div> -->


            <!-- <van-popup v-model="showTips" class="popOuts" :close-on-click-overlay="false">
            <div class="popTips">
                <div class="popTop">
                    <div class="popTitle">重要提醒</div>
                    <div class="popCont">
                        请先去安全中心设置邮箱及资金密码
                    </div>
                </div>
                <van-button :disabled="time > 0" class="popBtn" :loading="fiveLoading" @click="closeTips">我知道了<span
                        v-show="time > 0">({{ time }}s)</span></van-button>
            </div>
        </van-popup> -->


        </div>
    </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { userInfo, withdrawChannel, walletList, withdrawApply, withdrawConfig,platformBalance,platformRecovery } from '@/request/api'
export default {
    components: {},
    props: {
        withShow: Boolean,
    },
    name: 'withdrawalIndex',
    data() {
        return {
            checked: true,
            list: [

            ],
            activeLink: 0,
            linkList: [],
            aount: '',
            subLoading: false,
            getUserLoading: false,
            balance: 0,
            lockAmount: 0,
            getchannelLoading: false,
            minAmount: 0,
            maxAmount: 0,
            total: 0,
            walletLoading: false,
            totalUsdt: 0,
            usdList: [],
            configMaxAmount: 0,
            withdrawCount: 0,
            accumulateWithdrawAmount: 0,
            showTips: false,
            fiveLoading: false,
            time: 3,
            totalAmount: 0,
            usdt:0,
            freeze:0,
            actWith:"",
            actSeleWith:-1,

      showAddLink: false,
  
      balanceLoading: false,
      getWalletLoading: false,
      recoveryLoading: false,
        }
    },
    computed: {
    ...mapState({
        withdrawalShow: (state) => state.popShow.withdrawalShow,
    }),
  },
    mounted() {
        this.getPlatformBalance();
        this.getUserInfo()
        // this.getwithdrawChannel()
        this.getWalletList()
        this.getWithdrawConfig()
    },
    methods: {
        ...mapActions(['setActivePop']),
        closeDialog(fieldName) {
            this.setActivePop(fieldName);
            },
            getService(){
                this.$store.commit("goService")
            },
        upLoginShow(newValue) {
            this.$emit('update:withShow', newValue);
        },

        recovery() {
      if (this.recoveryLoading) {
        return;
      }
      this.recoveryLoading = true;
      platformRecovery().then((res) => {
        if (res.code == 0) {
          this.balance =
            res.data.balance + res.data.totalAmount + res.data.freeze;
          this.totalAmount = res.data.balance;
          this.freeze = res.data.freeze;
          this.$notify({
            message: "回收成功",
            duration: 1000,
            background: "#1989fa",
          });
        }
        this.recoveryLoading = false;
      });
    },
    focusAount() {
      if (this.actWith == "银行卡") {
        if (this.total <= 0) {
          this.showAddLink = true;
        }
      } else {
        if (this.totalUsdt <= 0) {
          this.showAddLink = true;
        }
      }
    },
        closeTips() {
            this.$router.push('/setup')
        },
        submit() {
            if (this.amount < this.minAmount) {
        this.$message.warning("小于最小金额，请重新设置")
        return;
      }
      if (this.amount > this.maxAmount) {
        this.$message.warning("超出取款最大金额，请重新设置")
        return;
      }
            this.subLoading = true;
            var opt = {
                amount: this.aount,
                channelId: this.list[this.actSeleWith].id,
                walletId:
          this.actWith == "银行卡"
            ? this.linkList[this.activeLink].id
            : this.usdList[this.activeLink].id,
            }
            withdrawApply(opt).then((res) => {
                if (res.code == 0) {
                    this.$message.success("取款成功");
                    this.init();
                }
                setTimeout(() => {
                    this.subLoading = false;
                }, 2000);
            })
        },
        goAdd(pathUrl){
            this.closeDialog('all')
            this.$router.push(pathUrl)
        },
        init() {
            if (this.walletLoading) {
                return;
            }
            if (this.getchannelLoading) {
                return;
            }
            if (this.getUserLoading) {
                return;
            }
            this.total = 0;
            this.totalUsdt = 0;
            this.linkList = [];
            this.usdList = [];
            this.activeLink = 0
            this.getPlatformBalance();
            this.getUserInfo()
            // this.getwithdrawChannel()
            this.getWalletList()
            this.getWithdrawConfig()

        },

        maxMoney() {
            this.aount = this.totalAmount;
        },
        selectAct(item, index) {
            // this.actWith = index
            // this.activeLink = 0
            // this.minAmount = this.list[index].minAmount
            // this.maxAmount = this.list[index].maxAmount
            // this.aount = ""
            this.actWith = item.channelTypeName;
            this.actSeleWith = index;
            this.activeLink = 0;
            this.minAmount = this.list[index].minAmount;
            this.maxAmount = this.list[index].maxAmount;
            this.aount = "";
        },
        getPlatformBalance() {
      this.getWalletLoading = true;
      platformBalance().then((res) => {
        if (res.code == 0) {
       
          this.balance =
            res.data.balance + res.data.totalAmount + res.data.freeze;
          this.totalAmount = res.data.balance;
          this.freeze = res.data.freeze;
        }
        this.getWalletLoading = false;
      });
    },
        getwithdrawChannel() {
            this.getchannelLoading = true;
            withdrawChannel().then((res) => {
                if (res.code == 0) {
                    this.list = res.data
                    this.actWith = 0
                    this.minAmount = res.data[0].minAmount
                    this.maxAmount = res.data[0].maxAmount
                }
                this.getchannelLoading = false;
            })
        },
        getWithdrawConfig() {
            this.getchannelLoading = true;
            withdrawConfig().then((res) => {
                if (res.code == 0) {
                    // console.log(res)
                    // this.list = res.data.channels
                    // this.actWith = 0
                    // this.minAmount = res.data.channels[0].minAmount
                    // this.maxAmount = res.data.channels[0].maxAmount
                    // this.withdrawCount = res.data.withdrawCount
                    // this.configMaxAmount = res.data.maxAmount
                    // this.accumulateWithdrawAmount = res.data.accumulateWithdrawAmount

                    this.list = res.data.channels.reverse();

                    // this.actWith = res.data.channels[0].channelTypeName//银行卡，虚拟币，微信，支付宝
                    // this.actSeleWith = 0;
                    if(this.actSeleWith != -1){
            this.minAmount = this.list[this.actSeleWith].minAmount;
          this.maxAmount = this.list[this.actSeleWith].maxAmount;
          }else{
              this.minAmount = this.list[0].minAmount;
          this.maxAmount = this.list[0].maxAmount;
            }
                    this.withdrawCount = res.data.withdrawCount;
                    this.configMaxAmount = res.data.maxAmount;
                    this.accumulateWithdrawAmount = res.data.accumulateWithdrawAmount;
                    this.usdt = res.data.usdt;
                }
                this.getchannelLoading = false;
            })
        },
        getWalletList() {
            this.walletLoading = true;
            walletList().then((res) => {
                if (res.code == 0) {
                    console.log(res)
                    for (var i = 0; i < res.data.length; i++) {
                        if (res.data[i].typeId == 1) {
                            this.total++;
                            this.linkList.push(res.data[i])
                        }
                        if (res.data[i].typeId == 2) {
                            this.totalUsdt++;
                            this.usdList.push(res.data[i])

                        }
                    }
                }
                this.walletLoading = false;
            })
        },
        getUserInfo() {
            this.getUserLoading = true;
            userInfo().then((res) => {
                if (res.code == 0) {
                    // this.balance = res.data.balance
                    // this.lockAmount = res.data.lockAmount
                    if (!res.data.isSettingFundPassword) {
                        var that = this;
                        this.time = 3;
                        this.showTips = true;
                        setInterval(() => {
                            that.time--;
                        }, 1000);
                    }
                }
                this.getUserLoading = false;
            })
        },
        hideMiddle(value) {
            if (!value) {
                return;
            }
            // 获取字符串的长度
            var length = value.length;
            // 计算需要隐藏的字符数
            var hideCount = Math.max(length - 8, 0);
            // 计算需要隐藏的字符的起始索引
            var start = Math.floor((length - hideCount) / 2);
            // 构造隐藏部分的字符串，加上 <span> 标签
            var hidden = '<span >' + '  ' + '*'.repeat(4) + '  ' + '*'.repeat(4) + '  ' + '</span>';
            // 替换原始字符串中间的字符为隐藏的字符
            return value.substring(0, start) + hidden + value.substring(start + hideCount);
        }
    },
    filters:{
    formatNumber(value){
      if (!value) return value;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  }
}
</script>

<style lang="less" scoped>
.withDepostDialog {
    /deep/.el-dialog {
        display: flex;
        width: 700px;

        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        border-radius: 10px;
        background: radial-gradient(133.65% 137.5% at -19.87% -5.42%, #02568E 6%, #13293B 61.18%, #152B3F 100%);

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            width: 100%;
            padding: 20px;
        }
    }

    .diaTitle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        .diaTit {
            color: #FFF;
            font-family: "PingFang SC";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;

        }

        img {
            cursor: pointer;
            width: 40px;
            height: 40px;
        }
    }

}



.contents {
    // padding-bottom: 20px;

    .wbxqblm {
        width: 100%;
        display: flex;
        padding: 10px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        border-radius: 10px;
        //border: 0.5px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.04);
        margin-top: 14px;

        .intPost {

            height: 48px;
            display: flex;
            padding: 12px 12px 12px 48px;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.04);
            background: rgba(255, 255, 255, 0.09);

            margin-bottom: 6px;

            .intLeft {
                display: flex;
                align-items: center;
                position: relative;
                width: 100%;

                .dollarImg {
                    width: 48px;
                    height: 48px;
                    flex-shrink: 0;
                    fill: #FF3C44;
                    filter: drop-shadow(0px 8px 12px rgba(255, 60, 68, 0.40));
                    position: absolute;
                    left: -48px;
                    top: 6px;
                }

                .amoutInt {
                    /deep/.el-input__inner {
                        padding-left: 0;
                        background: transparent;
                        border: 0;
                        color: #fff;


                    }

                    /deep/.el-input__inner::-webkit-outer-spin-button,
                    /deep/.el-input__inner::-webkit-inner-spin-button {
                        -webkit-appearance: none !important;
                        -moz-appearance: none !important;
                        -o-appearance: none !important;
                        -ms-appearance: none !important;
                        appearance: none !important;
                        margin: 0;
                    }

                    input[type="number"] {
                        -webkit-appearance: textfield;
                        -moz-appearance: textfield;
                        -o-appearance: textfield;
                        -ms-appearance: textfield;
                        appearance: textfield;
                    }


                }

                .colse {
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .intRight {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba(255, 255, 255, 0.65);
                    /* 内容/M-大多数文字 */
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 200;

                    .rmbShu {
                        width: 1px;
                        height: 12px;
                        background: rgba(255, 255, 255, 0.45);
                        margin: 0 10px;
                    }

                    .maxBtn {
                        color: #0385FF;
                        line-height: 44px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        white-space: nowrap;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }

            .intRight {
                color: rgba(255, 255, 255, 0.45);
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }
    }


    .withTop {
        display: flex;
        width: 100%;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.04);
        // padding-bottom: 12px;
        flex-wrap: wrap;
        margin: 12px auto 0 auto;
        transition: all 1s;

        .blueTop {
            border-radius: 8px;
            background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 12px;

            .blueLeft {
                .leftTop {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 300px;
                }

                .blueTitle {
                    display: flex;
                    align-items: center;
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                    }
                }

                .numbers {

                    color: #FFF;
                    font-family: DIN;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    text-align: left;
                    margin-top: 2px;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }

                .twoTt {
                    margin-top: 10px;
                }



                .blueRight {
                    height: 22px;

                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    border-radius: 18px;
                    background: rgba(255, 255, 255, 0.25);
                    display: flex;
                    padding: 2px 6px;
                    justify-content: center;
                    align-items: center;
                    gap: 2px;
                    user-select: none;
            cursor: pointer;
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .shu {
                width: 0.5px;
                height: 54px;
                background: rgba(255, 255, 255, 0.25);
                margin: 0 auto;
            }

            .blue-new-right {
                width: 300px;

                .blueTitle {
                    text-align: left;
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                }

                .subNumbers {
                    color: #FFF;
                    font-family: DIN;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    text-align: left;
                    margin-top: 2px;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }


        }

        .openCo {
            color: #FFF;
            /* 内容/XS-辅助性文字 */
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 200;
            display: flex;
            align-items: center;
            padding-left: 10px;
            margin-top: 12px;

            .open {

                margin-right: 3px;
            }
        }

        .blueOne {
            margin-top: 12px;
        }

        .blueBom {
            width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;

            .withTit {
                width: 19%;

                .withTitle {
                    color: rgba(255, 255, 255, 0.65);
                    text-align: center;
                    width: 100%;
                    /* 内容/XS-辅助性文字 */
                    font-family: "PingFang SC";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;

                }

                .cont {
                    color: #FFF;
                    text-align: center;
                    font-family: DIN;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    margin-top: 2px;
                }
            }

            .bomShu {
                width: 0.5px;
                height: 20px;
                background: rgba(255, 255, 255, 0.25);
            }
        }
    }

    .title {
        width: 100%;
        margin: 0 auto 14px auto;
        color: #FFF;
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .blueVertical {
            width: 4px;
            height: 32px;
            border-radius: 0px 8px 8px 0px;
            background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
            margin-right: 10px;
        }

        .titRight {
            display: flex;
            align-items: center;

            img {
                width: 16px;
                height: 16px;
                margin-left: 3px;
            }
        }

        .titBtn {
            color: #0385FF;
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.09);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 59px;
            margin-left: 10px;
            height: 22px;
            margin-top: 5px;
            user-select: none;
            cursor: pointer;
            img {
                width: 16px;
                height: 16px;
                margin-right: 1px;
            }
        }

        .jrTs {
            display: flex;
            padding: 5px 10px;
            align-items: center;
            gap: 5px;
            flex: 1 0 0;
            border-radius: 10px;
            background: rgba(44, 209, 88, 0.09);
            color: rgba(255, 255, 255, 0.45);
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-left: 10px;
            margin-top: 4px;
            text-align: left;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    .seleDemo {
        width: 100%;
        overflow-x: auto;

        .seleContent {
            display: flex;

        }

        .seleList {
            position: relative;
            min-width: 96px;
            height: 47.731px;
            padding: 4px 16px;
            flex-direction: column;
            border-radius: 8px;
            border: 1px solid  rgba(255, 255, 255, 0.15);
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 12px;
            user-select: none;
            cursor: pointer;
            .customBom {
                opacity: 0;
                position: absolute;
                right: 0px;
                bottom: 0px;
                background-image: url('../../assets/postWith/newd.svg');
                background-repeat: no-repeat;
                background-size: cover;
                width: 20px;
                height: 20px;
            }
        }

        .active {

            background: #283E4C;
            border: 1px solid #0385FF;

            .customBom {
                opacity: 1 !important;
            }

        }
    }

    .seleDemo::-webkit-scrollbar {
        top: 5px;
    }

    .tis {
        color: #FFF;
        width: 100%;
        margin: 0 auto;
        text-align: left;
        /* 内容/S-辅助性文字 */
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 100;
    }

    .ckydwt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top:14px;
        .encounter {
            display: flex;
            align-items: center;
            white-space: nowrap;
            justify-content: center;

            img {
                margin: 0 5px 0 10px;
                cursor: pointer;
                user-select: none;
            }

            span {
                cursor: pointer;
                user-select: none;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                background: linear-gradient(180deg, #FFF -54.54%, #C4DEFF 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .btn {
            display: flex;
            width: 280px;
            height: 46px;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 0;
            background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
            color: #FFF;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            /* 24px */
        }
    }

    .linkDemo {
        margin-top: 10px;
        width: 100%;
        .linkContent {
            color: #FFF;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
            height: 40px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.09);
            user-select: none;
            cursor: pointer;
            .linkLeft {
                display: flex;
                align-items: center;

                .btTit {
                    font-weight: 100;
                }

                .linkShu {
                    width: 1px;
                    height: 20px;
                    border-radius: 3px;
                    background: rgba(255, 255, 255, 0.35);
                    margin: auto 10px;
                }
            }

            .dhRight {
                width: 20px;
                height: 16px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }



    .withInt {
        display: flex;
        width: 100%;
        margin: 10px auto;
        padding: 12px;
        align-items: center;
        gap: 10px;
        display: flex;
        justify-content: space-between;
        height: 45px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.04);
        background: var(---4, rgba(255, 255, 255, 0.04));

        .ints {
            background: transparent;
            border: 0;
            height: 45px;
            color: #fff;
            padding-left: 0;
            width: 72%;
        }


    }

    .yuji {
        color: rgba(255, 255, 255, 0.65);
        /* 内容/S-辅助性文字 */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        margin: 2px auto 0 auto;
        width: 100%;
        justify-content: space-between;

        span {
            color: #0385FF;
        }
    }

    .wlfb {
        color: rgba(255, 255, 255, 0.45);
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 200;
        width: 100%;
        margin: 15px auto 0 auto;
        text-align: left;
    }

  
}

.popOuts {
    background: transparent;
}

.popTips {
    border-radius: 8px;
    background: #1E303A;
    overflow: hidden;

    .popTop {
        width: 290px;
        height: 90px;
        padding: 12px;
        border-radius: 8px 8px 0 0;

        .popTitle {
            color: #FFF;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            margin-bottom: 25px;
        }

        .popCont {
            color: #FF4337;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .popBtn {
        width: 245px;
        height: 48px;
        color: #0385FF;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        border: 0;
        border-radius: 0px 0px 8px 8px;
        border-top: 1px solid #19262E;
        background: #1E303A;
    }
}

/deep/.van-field__control {
    color: #fff;
}

.reload {
    user-select: none;
            cursor: pointer;
    &.rotate {
        animation: spin 1s linear infinite;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


</style>