var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homeDemo",style:({ height: _vm.isFold ? '38px' : 'auto' })},[_c('div',{ref:"scrollTarget",staticClass:"fold"},[_c('div',{staticClass:"foldLeft"},[_c('img',{attrs:{"src":_vm.icon}}),_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"foldRight"})]),(
      _vm.name == '体育' || _vm.name == '区块链' || _vm.name == '电竞' || _vm.name == '彩票'
    )?_c('div',{staticClass:"sportListOut"},_vm._l((_vm.list),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.status != 2 && item.id != 10),expression:"item.status != 2 && item.id != 10"}],key:index,staticClass:"widthDemo",style:({
        backgroundImage: `url(${item.h5Image})`,
        backgroundSize: '100% 100%',
      }),on:{"click":function($event){return _vm.goPage(item)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.status != 0),expression:"item.status != 0"}],staticClass:"maintainDemo"},[_c('img',{attrs:{"src":require("@/assets/home/tab/homeWhz.svg")}}),_vm._v(_vm._s(_vm._f("getStatus")(item.status))+" ")])])}),0):_c('div',{staticClass:"smailOut",class:{ zerenOut: _vm.name == '真人' }},_vm._l((_vm.list),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.status != 2),expression:"item.status != 2"}],key:index,staticClass:"smailList",class:{ caicaic: _vm.name == '彩票', zeren: _vm.name == '真人' },style:({
        backgroundImage: `url(${_vm.name == '真人'?item.pcImage:item.h5Image})`,
        backgroundSize: '100% 100%',
      }),on:{"click":function($event){return _vm.goPage(item)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.status != 0),expression:"item.status != 0"}],staticClass:"smailMaintainDemo"},[_c('img',{attrs:{"src":require("@/assets/home/tab/homeWhz.svg")}}),_vm._v(_vm._s(_vm._f("getStatus")(item.status))+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }